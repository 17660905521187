<script lang="ts" setup>
import { getTopBlogs } from '@/api/blog';
import type { Blog } from '@/model/model';
import { onMounted, ref } from '@vue/runtime-core';
import AnnouncementItem from '../annoucement/AnnouncementItem.vue';

const blogs = ref<Blog[]>([])

onMounted( async () => {
  const resp = await getTopBlogs("announcement")
  if(resp.code == 200){
    blogs.value = resp.data!
  }
})
</script>

<template>
  <div class="px-4 mx-auto mt-12 xl:container xl:px-0 xl:max-w-7xl">
    <div class="flex">
      <div class="flex-1 text-2xl sm:text-3xl font-bold">Announcements</div>
      <a class="self-end text-base text-right text-mainGreen" href="/blogs?category=announcement">
        See more >
      </a>
    </div>
    <div class="grid-cols-1 mt-4 divide-y gird">
      <div></div>
      <div></div>
    </div>
    <div class="pt-8 grid gap-y-4 sm:gap-y-0 lg:grid-cols-4 lg:gap-x-12">
      <div v-for="(item, idx) in blogs">
        <AnnouncementItem
            :title="item.title"
            :bref="item.bref"
            :created_at="item.created_at"
            :sub_category="item.sub_category"
            :id = "item.id"
            :image="item.cover"
        ></AnnouncementItem>
      </div>
    </div>
  </div>
</template>
