<script setup lang="ts">
import { RouterLink, RouterView } from 'vue-router';
import TopNav from './components/app/TopNav.vue';
import { onMounted } from 'vue';
import { setGlobalToken } from './api/global';
import { useAccountStore } from './stores/modules/account';
import { observer } from './utils/Observable';

onMounted(() => {
  const accountStore = useAccountStore();

  if (accountStore.token) {
    observer.addObserver(accountStore.token);
  }
  console.log(accountStore.token);
  setGlobalToken(accountStore.token);

});
</script>

<template>
  <TopNav />
  <RouterView :key="$route.fullPath"/>
</template>
