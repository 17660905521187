import type { NewNotification } from "@/model/model";
import { API_USER_NEW_NOTIFICATION } from "@/utils/const";
import { mande } from "mande";
import type { BaseResponse, NewNotifcationResponse, NewNotificationDetail } from './../model/reqRes';

const userNewNotificationApi = () => mande(API_USER_NEW_NOTIFICATION);

export async function getUserUnreadNewNotifications() {
  return await userNewNotificationApi()
    .get<BaseResponse<NewNotification[]>>('/unread')
    .catch((error) => {
        return { code : 500, message: 'server error', data: null} as BaseResponse<NewNotification[]>; 
    });
}

export async function readNewNotification(id:number){
  return await userNewNotificationApi()
  .get<BaseResponse<string>>('/read/' + id.toString())
  .catch((error) => {
    return { code : 500, message: 'server error', data: null} as BaseResponse<NewNotification[]>; 
});
}

export async function getUserNewNotifications(pageIndex:number, pageSize:number){
  return await userNewNotificationApi().get<BaseResponse<NewNotifcationResponse>>('/' + pageIndex + '/' + pageSize)
  .catch((error) => {
    return { code : 500, message: 'server error', data: null} as BaseResponse<NewNotifcationResponse>;
  });
}

export async function getUserNewNotificationsDetail(){
  return await userNewNotificationApi().get<BaseResponse<NewNotificationDetail[]>>('/detail')
  .catch((error) => {
    return { code : 500, message: 'server error', data: null} as BaseResponse<NewNotificationDetail[]>;
  });
}

export async function getUserNewNofiticationsByCategory(category:string, pageIndex:number, pageSize:number){
  return await userNewNotificationApi().get<BaseResponse<NewNotificationDetail[]>>('/by/' + category + '/' + pageIndex + '/' + pageSize)
  .catch((error) => {
    return { code : 500, message: 'server error', data: null} as BaseResponse<NewNotificationDetail[]>;
  });
}

export async function readUserNewNotificationsByCategory(category:string){
  return await userNewNotificationApi().get<BaseResponse<string>>('/read/by/' + category)
  .catch((error) => {
    return { code : 500, message: 'server error', data: null} as BaseResponse<NewNotificationDetail[]>;
  });
}