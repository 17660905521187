<script lang="ts" setup>import { computed } from '@vue/runtime-core';

const props = defineProps({
    category: String,
    status: String
})
const imgName = computed(()=>{
    if(props.category === 'Wind'){
        return '/images/wind_rec.jpg'
    } else if(props.category === 'Solar'){
        return '/images/solar_rec.jpg'
    } else if (props.category === 'Hydro'){
        return '/images/hydro_rec.jpg'
    } else if (props.category === 'Geothermal'){
        return '/images/geothermal_rec.jpg'
    } else if (props.category === 'Biomass') {
        return '/images/biomass_rec.jpg'
    }
})
</script>

<template>
    <div class="bg-auto bg-no-repeat bg-60% bg-right-bottom" style="background-image: url('/images/soldout.png')" v-if="status === 'Canceled' || status === 'Sold'" >
        <img class="object-fill rounded grayscale opacity-70" :src="imgName"/>
    </div>
    <div class="bg-auto bg-no-repeat bg-60% bg-right-bottom" style="background-image: url('/images/expired.png')" v-else-if="status === 'Expired'" >
        <img class="object-fill rounded grayscale opacity-70" :src="imgName"/>
    </div> 
    <img v-else class="object-fill rounded" :src="imgName"/>
</template>