import {
  ConstantBackoff,
  WebsocketBuilder,
  type Websocket
} from 'websocket-ts';
import { WS_URL } from './const';
import { v4 as uuidv4 } from 'uuid';

interface Observable {
  token: string;
  id: string;
  ws: Websocket;
}

export interface Message {
  event_type: string;
  message_data: MessageData;
  title: string;
  client_id:string
}

export interface MessageData {
  author: string
  type: string
  data: any
  rec_id: number
  from: number
  to: number
  uuid:string
  price:number
  amount:number
  user_name:string
  offer_id:number
  created_at:string
}

export type OnMessage = (message: Message) => {};
export class Observer {
  private ob: Observable | null = null;
  private listeners :Set<OnMessage> = new Set();
  addObserver(token: string) {
    const id = uuidv4();
    console.log('add observer ...')
    console.log(WS_URL + '1/rec-server/client/ws?token=' + token + '&id=' + id)
    let ws = new WebsocketBuilder(
      WS_URL + '1/rec-server/client/ws?token=' + token + '&id=' + id
    )
      .withBackoff(new ConstantBackoff(1000))
      .onOpen((i, ev) => {
        console.log('opened');
      })
      .onClose((i, ev) => {
        console.log('closed');
      })
      .onError((i, ev) => {
        console.log(i)
        console.log(ev)
        console.log('error');
      })
      .onMessage((i, ev: MessageEvent) => {
        console.log(i, ev);
        const message: Message = JSON.parse(ev.data);
        this.listeners.forEach(onMessage => onMessage(message));
      })
      .onRetry((i, ev) => {
        console.log('retry');
      })
      .build();

    if (this.ob) {
      this.close();
    }
    this.ob = {
      token,
      id,
      ws
    };
  }

  addListener(f: OnMessage) {
    this.listeners.add(f);
  }

  removeListener(f: OnMessage) {
    this.listeners.delete(f);
  }

  close() {
    if (this.ob != null) {
      try {
        this.ob.ws.close();
      } catch (e) {}
    }
    this.ob = null;
  }

  sendMessage(message: Message) {
    if (this.ob != null) {
      const strMessage = JSON.stringify(message);
      console.log('sendMessage -------> ' + strMessage);
      this.ob.ws.send(strMessage);
    }
  }
}

export let observer = new Observer();
