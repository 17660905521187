import { getWebSettings } from "@/api/commonData";

import { defineStore } from "pinia";
import { useBlockchainStore } from "./blockchain";

export const useWebSettingsStore = defineStore({
    id: 'webSettings',
    state: () => ({
        testnet: false,
        lastUpdateTs: 0,
        showModals: {
            chooseWalletModal: false, 
        }
    }),
    actions: {
        needRefresh(){
            return this.lastUpdateTs + 3600 * 1000 < Date.now();
        },
        async initWebSettings() {
            if (!this.needRefresh()) return;
            const res = await getWebSettings();
            console.log(res)
            if (res.code == 200) {
                this.testnet = res.data!.testnet;
                const blockchainStore = useBlockchainStore()
                blockchainStore.polygonNetwork = res.data!.polygon_network
                blockchainStore.nftAddress = res.data!.nft_address
                blockchainStore.marketplaceAddress = res.data!.marketplace_address
                blockchainStore.tokenAddress = res.data!.token_address
                this.lastUpdateTs = Date.now();
            }
        },
        
    },
    persist: {
        enabled: true,
        strategies: [
          {
            key: 'webSettings',
            storage: localStorage,
          }
        ]
    }
})