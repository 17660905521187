<script lang="ts" setup>
import { ref, watch } from '@vue/runtime-core';

export interface Props {
    open: boolean
    title?: string
    successButtionText?: string
    showFooter?: boolean,
    showCloseButton?: boolean,
    showTitle?: boolean
}

const emit = defineEmits<{
    (e: 'close') :void 
    (e: 'confirm') :void
}>()

const props = withDefaults(defineProps<Props>(), {
    showFooter: true,
    showCloseButton: true,
    showTitle: true,
})

watch(()=>props.open, (val)=>{
    console.log(val)
    showModal.value = val
})

const showModal = ref(props.open)

function onclose(){
    showModal.value = false
    emit('close')
}

function onconfirm(){
    emit('confirm')
}
</script>

<template>
    <Teleport to="body">
        <div :class="{hidden:!showModal}" class="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50">
            <div class="m-4 p-4 bg-white rounded-xl">
                <div class="relative flex items-center justify-center h-12 mb-3 " :class="{hidden:!props.showTitle}">
                    <slot name="title">
                        <p class="text-xl font-semibold text-center">{{props.title? props.title : ''}}</p>
                    </slot>
                    <button :class="[props.showCloseButton ? '' : 'hidden']" class="absolute right-0 p-1 bg-white rounded-full top-2" @click="onclose">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <slot name="content">
                    <p></p>
                </slot>
                <slot name="footer">
                    <div class="flex" :class="{hidden:!props.showFooter}">
                        <div class="flex-1"></div>
                        <button class="w-24 px-6 py-2 mr-12 text-base font-semibold border rounded" @click="onclose">Cancel</button>
                        <button class="w-24 px-6 py-2 text-base font-semibold text-white rounded bg-mainGreen" @click="onconfirm">{{props.successButtionText? props.successButtionText : "Ok"}}</button>
                    </div>
                </slot>
            </div>
        </div>
    </Teleport>
</template>