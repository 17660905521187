<script lang="ts" setup>
import {dateTimeStringToDate} from '@/utils/string_utils';
import { useRoute, useRouter } from 'vue-router';
const router = useRouter()
function blogClick(id:Number){
  router.push('/blog?id=' + id)
}
const props = defineProps({
  title: String,
  bref: String,
  sub_category: String,
  created_at: String,
  id: Number,
  image: String
});
</script>

<template>
    <div class="mt-4 border-2 first:mt-0 lg:mt-0 rounded-xl border-borderGray" @click="blogClick(props.id!)">
      <img class="object-cover w-full bg-gray-100 h-60 rounded-t-xl" :src=props.image />
      <div class="px-4 pt-4 border-t"><span class="px-2 py-1 text-xs text-white rounded bg-linkColor">{{props.sub_category}}</span></div>
      <div class="px-4 pt-2 text-xl font-bold truncate overflow-clip">
        {{props.title}}
      </div>
      <div class="px-4 pt-2 text-base text-mainFontGray">{{dateTimeStringToDate(props.created_at!)}}</div>
      <div class="px-4 pt-2 pb-6 text-base text-mainFontGray">
        {{props.bref}}
      </div>
    </div>

</template>
