import { defineStore } from "pinia";
import type { PolygonNetwork } from "@/model/reqRes";
import { ethers } from "ethers";
import { recNftAbi } from "@/utils/recNftAbi";
import { recMarketplaceAbi } from '@/utils/recMarketplaceAbi';
import { usdtABI } from '@/utils/usdtAbi'
import { all } from "mathjs";
import type { get } from "http";


export const useBlockchainStore = defineStore({
    id: 'blockchain',
    state: () => ({
        metaMaskConnected: false,
        polygonNetwork: {} as PolygonNetwork,
        nftAddress: '',
        marketplaceAddress: '',
        tokenAddress: '',
    }),
    getters: {
        ethereum(){
            return window.ethereum
        },
        povider(){
            return new ethers.providers.Web3Provider(this.ethereum, "any")
        },
    },
    actions: {
        async checkConnectMetaMaskNetwork() {
            try{
                const provider = this.povider
                const network = await provider.getNetwork()
                console.log("network", network)
                return network.chainId == this.polygonNetwork.chain_id
            } catch(err){
                console.log(err)
                return false
            }
        },
        async switchToTargetPolygonNetwork() {
            try {
                await this.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: this.polygonNetwork.id_to_hex }],
                })
                return true
            }catch(switchError){
                console.log(switchError)
                if(switchError.code !== 4902) return false;
                try {
                    await this.ethereum.request({
                        method: 'wallet_addEthereumChain',
                            params: [
                            {
                                chainId: this.polygonNetwork.id_to_hex,
                                chainName: this.polygonNetwork.chain_name,
                                nativeCurrency: {
                                    name: "MATIC",
                                    symbol: "MATIC", // 2-6 characters long
                                    decimals: 18,
                                },
                                rpcUrls: this.polygonNetwork.rpc_urls.slice(),
                                blockExplorerUrls: this.polygonNetwork.block_explorer_urls.slice()
                            }
                            ],
                    })
                    return true
                }catch(addError){
                    console.log(addError)
                    return false
                }
            }
        },
        async connectToMetaMask(){
            try {
                const provider = this.povider
                provider.on("network", (newNetwork, oldNetwork) =>{
                    console.log("network changed", newNetwork, oldNetwork)
                    if(oldNetwork != null){
                        console.log("refresh network")
                        window.location.reload()
                    }
                })
                await provider.send("eth_requestAccounts", [])
                const signer = provider.getSigner()
                const address = await signer.getAddress()
                console.log(address)
                this.metaMaskConnected = true
                return address
            }catch(err){
                console.log(err)
                return '' 
            }
        },
        async isMetaMaskConnected(address:string) {
            const accounts = await this.ethereum.request({method: 'eth_accounts'});
            if(accounts.length) {
                console.log('you are connected to ' + accounts[0]);
                this.metaMaskConnected = accounts[0].toLowerCase() == address.toLowerCase();
                return true
            } else {
                console.log('Metamask is not connected');
                this.metaMaskConnected = false;
                return false
            }
        },
        async sendApprovalForAll(){
            try {
                const provider = this.povider
                await provider.send("eth_requestAccounts", [])
                const signer = provider.getSigner()
                const nftContract = new ethers.Contract(this.nftAddress, recNftAbi, signer)
                const tx = await nftContract.setApprovalForAll(this.marketplaceAddress, true)
                return tx
            }catch(err){
                console.log(err)
                return '' 
            }
        },
        async checkIsApprovedForAll(){
            try {
                const provider = this.povider
                await provider.send("eth_requestAccounts", [])
                const signer = provider.getSigner()
                const nftContract = new ethers.Contract(this.nftAddress, recNftAbi, signer)
                const isApproved = await nftContract.isApprovedForAll(await signer.getAddress(), this.marketplaceAddress)
                console.log("marketplace contract is appored:",isApproved)
                return isApproved
            }catch(err){
                console.log(err)
                return false
            }
        },
        async approveTokens(amount:number){
            try {
                const provider = this.povider
                await provider.send("eth_requestAccounts", [])
                const signer = provider.getSigner()
                const erc20Token = new ethers.Contract(this.tokenAddress, usdtABI, signer)
                const tx = await erc20Token.approve(this.marketplaceAddress, amount)
                console.log(tx)
                return tx 
            } catch(err){
                console.log(err)
                return ''
            }
        },
        async allowanceTokens(owner:string, spender:string) {
            try {
                const provider = this.povider
                await provider.send("eth_requestAccounts", [])
                const signer = provider.getSigner()
                const erc20Token = new ethers.Contract(this.tokenAddress, usdtABI, signer)
                const allowance = await erc20Token.allowance(owner, spender)
                console.log(allowance)
               return allowance 
           } catch(err){
               console.log(err)
               return ''
           }
        },
        async listNFT(listId:number, tokenId:number, amount:number, price:number, exprity:number){
            try {
                const provider = this.povider
                await provider.send("eth_requestAccounts", [])
                const signer = provider.getSigner()
                const erc20Token = new ethers.Contract(this.tokenAddress, usdtABI, signer);
                const marketplaceContract = new ethers.Contract(this.marketplaceAddress, recMarketplaceAbi, signer)
                price = price /100 * 10 **( await erc20Token.decimals())
                console.log(listId, tokenId, amount, price, exprity)
                const tx = await marketplaceContract.listingNFT(listId, tokenId, amount, price, exprity)
                console.log(tx)
                return tx
            }catch(err){
                console.log(err);
                return ''
            }
        },
        async getListingNFT(listId:number){
            try{
                const provider = this.povider
                await provider.send("eth_requestAccounts", [])
                const signer = provider.getSigner()
                const marketplaceContract = new ethers.Contract(this.marketplaceAddress, recMarketplaceAbi, signer)
                const tx = await marketplaceContract.getListing(listId)
                console.log(tx)
                return tx
            }catch(err){
                console.log(err);
                return ''
            }
        },
        async purchaseNFT(listId:number, saleId:number, amount:number) {
            try {
                const provider = this.povider
                await provider.send("eth_requestAccounts", [])
                const signer = provider.getSigner()
                const marketplaceContract = new ethers.Contract(this.marketplaceAddress, recMarketplaceAbi, signer)
                //const estimateGas = await marketplaceContract.estimateGas.purchaseNFT(listId, amount)
                //const gasLimit = estimateGas.mul(110).div(100)
                const tx = await marketplaceContract.purchaseNFT(listId, saleId, amount, { gasLimit: 177255 })
                console.log(tx)
                return tx
            }catch(err){
                console.log(err)
                return ''
            }
        },
        async offerNFT(sellerAddress:string, offerId:number, tokenId:number, amount:number, price:number, exprity:number){
            try{
                const provider = this.povider
                await provider.send("eth_requestAccounts", [])
                const signer = provider.getSigner()
                const erc20Token = new ethers.Contract(this.tokenAddress, usdtABI, signer);
                const marketplaceContract = new ethers.Contract(this.marketplaceAddress, recMarketplaceAbi, signer)
                price = price /100 * 10 **( await erc20Token.decimals())
                console.log(sellerAddress, offerId, tokenId, amount, price, exprity)
                const tx = await marketplaceContract.offerNFT(sellerAddress, offerId, tokenId, amount, price, exprity)
                console.log(tx)
                return tx
            } catch(err){
                console.log(err)
                return ''
            }
        },
        async acceptOffer(offferId:number, amount:number, sellerNonce:string, sellerSign:string){
            try{
                const provider = this.povider
                await provider.send("eth_requestAccounts", [])
                const signer = provider.getSigner()
                const marketplaceContract = new ethers.Contract(this.marketplaceAddress, recMarketplaceAbi, signer)
                const tx = await marketplaceContract.acceptOffer(offferId, amount, sellerNonce, sellerSign,  { gasLimit: 177255 })
                console.log(tx)
                return tx
            }catch(err){
                console.log(err)
                return ''
            }
        },
        gotoTx(tx:string){
            const explorer_urls = this.polygonNetwork.block_explorer_urls
            console.log(explorer_urls[0])
            window.open(explorer_urls[0]+'/tx/'+tx, '_blank')
        },
        gotoAddress(address:string){
            const explorer_urls = this.polygonNetwork.block_explorer_urls
            console.log(explorer_urls[0])
            window.open(explorer_urls[0]+'/address/'+address, '_blank')
        },
        getPurchaseSignHash(listId:number, tokenId:number, price:number, amount:number, expirty:number, nonce:string) {
            return ethers.utils.solidityKeccak256(["uint256","uint256","uint256","uint256","uint256","string"],[listId, tokenId, price, amount, expirty, nonce])
        },
        getAcceptOfferSignHash(offerId:number, amount:number, nonce:string) {
            return ethers.utils.solidityKeccak256(["uint256","uint256","string"],[offerId, amount, nonce])
        },
        async signSignHash(messageHash:string){
            var sig = ''
            try {
                const provider = this.povider
                await provider.send("eth_requestAccounts", [])
                const signer = provider.getSigner()
                sig = await signer.signMessage(ethers.utils.arrayify(messageHash))
            } catch(err){
                console.log(err)
            }
            return sig
        }
    },
    persist: {
        enabled: true,
        strategies: [
          {
            key: 'blockchain',
            storage: localStorage,
          }
        ]
    }
})