import { mande } from 'mande';
import { API_Blogs, API_BLOG_BY_ID, API_Blog_detail,API_TOP_BLOGS } from './../utils/const';
import type { BaseResponse } from './../model/reqRes';
import type { Blog } from '@/model/model';

const blogs = () => mande(API_Blogs);
const blogDetail = () => mande(API_Blog_detail);
const topBlogsApi = ()=> mande(API_TOP_BLOGS)
const getServiceBlogIdApi  = () => mande(API_BLOG_BY_ID)


export async function getServiceBlogId(subCategory:string) {
  return getServiceBlogIdApi().get<BaseResponse<number>>('/Service Docs/' + subCategory).catch(error => {
    return { code: 500, message: 'server error', data: null } as BaseResponse<number>;});
}

export async function getBlogs(category: string) {
  return await blogs()
    .get<BaseResponse<Blog[]>>(category)
    .then((res) => {
      return res as BaseResponse<Blog[]>;
    })
    .catch((error) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<Blog[]>;
    });
}

export async function getTopBlogs(category: string) {
  return await topBlogsApi().get<BaseResponse<Blog[]>>(category).catch(error =>{
    return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftOrder[]>; 
  });
}

export async function getBlogDetail(id: string) {
  return await blogDetail()
    .get<BaseResponse<Blog>>(id)
    .catch((error) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<Blog>;
    });
}
