
import type {
    Conversion,
    CreateRecRequest, MakeNotifyRequest,
    MakeOfferRequest,
    MakeRecOfferSoldRequest, NotifyResponse,
    SearchNFTMintResponse, SearchRecResponse, UserConversion,
    SearchUserOfferRequest, SearchUserOfferResponse
} from '@/model/reqRes'
import { defaults, mande } from "mande";
import type { BaseResponse } from './../model/reqRes';
import {
    API_New_Rec,
    API_Fetch_Recs,
    API_New_Offer,
    API_Done_Offer,
    API_Fetch_Rec_Offer,
    API_Rec_By_Id,
    API_Rec_Chat_Users,
    API_Rec_Chat,
    API_Conversion,
    API_Rec_Seller_Chats,
    API_Rec_User_Chats,
    API_Dismiss_Offer, API_User_Conversion, API_New_Notify, API_Notification, API_save_kyc,
    API_GET_REC_OFFER_BY_ID,
    API_TOP_RECS,
    API_GET_USER_ASSET,
    API_GET_USER_ASSET_BY_REGISTRY,
    API_My_Made_Offers,
    API_My_Receive_Offers,
    API_Made_Rec_Sold,
    API_Rec_Cancel,
    API_Cancel_Offer,
} from './../utils/const'
import { getDataDictionary } from '@/api/commonData';
import type {RecAsset, RecItem, RecOffer, RecOfferChat, User} from '@/model/model'
import type {SearchNFTMarketRequest} from '@/model/reqRes'

const apiNewRec = ()=> mande(API_New_Rec)
const apiRecs = ()=> mande(API_Fetch_Recs)
const apiNewOffer = ()=> mande(API_New_Offer)
const apiDone = ()=> mande(API_Done_Offer)
const apiDismiss = ()=> mande(API_Dismiss_Offer)
const apiCancel = ()=> mande(API_Cancel_Offer)
const apiFetchRecOffer = ()=> mande(API_Fetch_Rec_Offer)
const apiGetRecById = ()=> mande(API_Rec_By_Id)
const apiGetChatUsers = ()=> mande(API_Rec_Chat_Users)
const apiMakeChat = ()=> mande(API_Rec_Chat)
const apiGetSellerChats = ()=> mande(API_Rec_Seller_Chats)
const apiGetConversion = ()=> mande(API_Conversion)
const apiUserConversions = ()=> mande(API_User_Conversion)
const apiGetUserChat = ()=> mande(API_Rec_User_Chats)
const apiNewNotify = ()=> mande(API_New_Notify)
const apiNotifies = ()=> mande(API_Notification)
const apiGetRecOfferApi = ()=> mande(API_GET_REC_OFFER_BY_ID)
const apiTopRecs = ()=> mande(API_TOP_RECS)
const apiGetUserAsset = ()=> mande(API_GET_USER_ASSET)
const apiGetUserAssetByRegistry = () => mande(API_GET_USER_ASSET_BY_REGISTRY)
const apiMyMadeOffers = ()=> mande(API_My_Made_Offers)
const apiMyReceiveOffers = ()=> mande(API_My_Receive_Offers)
const apiMadeRecSold = () => mande(API_Made_Rec_Sold)
const apiRecCancel = () => mande(API_Rec_Cancel)



export async function soldoutRec(id:string){
    return await apiMadeRecSold().get<BaseResponse<''>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<''>;
    });   
} 
export async function cancelRec(id:string){
    return await apiRecCancel().get<BaseResponse<''>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<''>;
    });   
} 



export async function getReceicedOffer(req: SearchUserOfferRequest){
    return await apiMyReceiveOffers().post<BaseResponse<SearchRecResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchRecResponse>;
    });   
} 
export async function getMadeOffer(req: SearchUserOfferRequest){
    return await apiMyMadeOffers().post<BaseResponse<SearchRecResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchRecResponse>;
    });   
} 

export async function getUserAsset(){
    return await apiGetUserAsset().get<BaseResponse<RecAsset[]>>('').catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecAsset[]>;
    });   
} 
      
export async function getUserAssetByRegistry(registry: string) {
    return await apiGetUserAssetByRegistry().get<BaseResponse<RecAsset[]>>(registry).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecAsset[]>;
    });
}

export async function getTopRecs(){
    return await apiTopRecs().get<BaseResponse<RecItem[]>>('').catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecItem[]>;
    });
}

export async function getRecOfferById(id: string){
    return await apiGetRecOfferApi().get<BaseResponse<RecOffer>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecOffer>;
    });
}


export async function createRec(req:CreateRecRequest) {
    return await apiNewRec().post<BaseResponse<RecItem>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecItem>;
    });
}

export async function getRecById(id: string) {
    return await apiGetRecById().get<BaseResponse<RecItem>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecItem>;
    });
}

export async function getRecs(req:SearchNFTMarketRequest) {
    // return await apiRecs().get<BaseResponse<RecItem[]>>("").catch(error =>{
    //     return { code : 500, message: 'server error', data: null} as BaseResponse<RecItem[]>;
    // });
    return await apiRecs().post<BaseResponse<SearchRecResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchRecResponse>;
    });
}

export async function makeOffer(req:MakeOfferRequest ) {
    return await apiNewOffer().post<BaseResponse<RecOffer>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecOffer>;
    });
}

export async function doneAOffer(req:MakeRecOfferSoldRequest) {
    return await apiDone().post<BaseResponse<string>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<string>;
    });
}

export async function dismissAOffer(req:MakeRecOfferSoldRequest) {
    return await apiDismiss().post<BaseResponse<string>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<string>;
    });
}
export async function cancelAOffer(req:MakeRecOfferSoldRequest) {
    return await apiCancel().post<BaseResponse<string>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<string>;
    });
}

export async function fetchRecOffer(id:string) {
    return await apiFetchRecOffer().get<BaseResponse<RecOffer[]>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecOffer[]>;
    });
}

export async function fetchRecChatUsers(id:string, chatUser:string) {
    return await apiGetChatUsers().get<BaseResponse<User>>(id + '/' + chatUser).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<User>;
    });
}

export async function makeChat(req:RecOfferChat) {
    return await apiMakeChat().post<BaseResponse<RecOfferChat>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecOfferChat>;
    });
}


export async function fetchSellerChats(recId:string) {
    return await apiGetSellerChats().get<BaseResponse<RecOfferChat[]>>(recId).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecOfferChat[]>;
    });
}

export async  function fetchConversion(recId:string){
    return await apiGetConversion().get<BaseResponse<Conversion[]>>(recId).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<Conversion[]>;
    });
}

export async function fetchUserChats(recId:string) {
    return await apiGetUserChat().get<BaseResponse<RecOfferChat[]>>(recId).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecOfferChat[]>;
    });
}

export async  function fetchUserConversion(){
    return await apiUserConversions().get<BaseResponse<UserConversion[]>>('').catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<UserConversion[]>;
    });
}

export async function newNotify(req:MakeNotifyRequest){
    return await apiNewNotify().post<BaseResponse<string>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<string>;
    });
}

export async  function fetchUserNotifications(){
    return await apiNotifies().get<BaseResponse<NotifyResponse[]>>('').catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<NotifyResponse[]>;
    });
}
