<script lang="ts" setup></script>

<template>
  <div class="px-4 py-12 mx-auto sm:py-24 xl:container xl:px-0 xl:max-w-7xl">
    <div class="flex">
      <div class="flex-1 text-2xl font-bold sm:text-3xl">Meet Grex</div>
      <a class="self-end text-base text-right text-mainGreen" href="/about">
        See more >
      </a>
    </div>
    <div class="grid-cols-1 mt-4 divide-y gird">
      <div></div>
      <div></div>
    </div>
    <div class="flex flex-wrap-reverse mt-8">
      <div class="flex-1">
        <div class="mt-4 text-xl font-bold sm:text-4xl sm:mt-0">
          Building a sustainable future in a transparent, traceable and authenticated ecosystem
        </div>
        <div class="mt-4 text-base sm:mt-6 sm:mr-10 sm:text-lg text-mainFontGray">
          <p>At Grex, we are enthusiastic about contributing and collaborating to achieve a net-zero economy in the digital world. A REC represents the environmental attributes of generating one-megawatt hour (MWh) of energy from renewable sources, known as an Energy Attribute Certificate (EAC). </p>
          <p class="mt-4">By combining REC and CC, GREX offers an innovative way to participate in sustainable energy initiatives. Through our platform, you can be a part of the movement towards a more sustainable future and contribute to the growth of renewable energy adoption.</p>
        </div>
      </div>
      <img class="object-cover sm:w-1/2 rounded-xl" src="/images/meet_us.jpg" />
    </div>
  </div>
</template>
