/**
 * @description: Request result set
 */
export enum HttpResultEnum {
  SUCCESS = 200,
  AUTH_ERROR = 401,
  ERROR = 500,
  JWTError = 301
}

export enum ContentTypeEnum {
  // json
  JSON = 'application/json;charset=UTF-8',
  // form-data qs
  FORM_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8',
  // form-data  upload
  FORM_DATA = 'multipart/form-data'
}
