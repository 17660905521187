import { mande } from 'mande';
import { API_New_Request, API_Fetch_Request, API_CONTACT_US} from './../utils/const';
import  type {BaseResponse} from '@/model/reqRes'
import  type {ContactUs, Request} from '@/model/model'

const new_request = () => mande(API_New_Request);
const requests = () => mande(API_Fetch_Request);
const contactUsRequest = () => mande(API_CONTACT_US);

export async function fetchRequests() {
    return await requests()
        .get<BaseResponse<Request[]>>('')
        .then((res) => {
            return res as BaseResponse<Request[]>;
        })
        .catch((error) => {
            return {
                code: 500,
                message: 'server error',
                data: null
            } as BaseResponse<Request[]>;
        });
}

export async function createRequest(req:Request) {
    return await new_request().post<BaseResponse<string>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<string>;
    });;
}

export async function createContactUs(req:ContactUs) {
    return await contactUsRequest().post<BaseResponse<string>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<string>;
    });;
}