<script setup lang="ts">
import HeroPart from '../components/index/HeroPart.vue';
import BannerPart from '../components/index/BannerPart.vue';
import RecPart from '../components/index/RecPart.vue';
import RecNftPart from '../components/index/RecNftPart.vue';
import AnnouncementPart from '../components/index/AnnouncementPart.vue';
import ResourcePart from '../components/index/ResourcePart.vue';
import MeetUsPart from '../components/index/MeetUsPart.vue';
import FooterVue from '@/components/app/Footer.vue';
import CarbonCreditPart from '@/components/index/CarbonCreditPart.vue';
import { useWeb3Store } from '@/stores/modules/web3';

const web3Store = useWeb3Store()
</script>

<template>
  <div>
    <!--Hero-->
    <HeroPart></HeroPart>

    <!-- banner -->
    <BannerPart></BannerPart>

    <!-- NFT -->
    <RecNftPart v-if="web3Store.enable"></RecNftPart>
    
    <!-- REC -->
    <RecPart></RecPart>

    <CarbonCreditPart></CarbonCreditPart>
   
    <!-- Announcements -->
    <AnnouncementPart></AnnouncementPart>

    <!-- Resources for getting started -->
    <ResourcePart></ResourcePart>

    <!-- Meet Recollar -->
    <MeetUsPart></MeetUsPart>

    <FooterVue></FooterVue>
  </div>
</template>
