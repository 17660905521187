import { mande } from 'mande';
import { defaults } from 'mande';

import {
  API_Review,
  API_Submit,
  API_Upload,
  API_UserInfo,
  API_save_kyc,
  API_Sync_Kyc,
  API_Login_Registrar
} from '@/utils/const';
import type {
  BaseResponse,
  LoginRequest,
  SaveKycRequest,
  SubmitKycRequest,
  SyncKycRequest,
  UserInfoRequest
} from './../model/reqRes';

import type { User } from '@/model/model';

const review_api = () => mande(API_Review);
const submit_api = () => mande(API_Submit);
const userinfo_api = () => mande(API_UserInfo);
const savekyc_api = () => mande(API_save_kyc);
const loginApiRegistrar = () => mande(API_Login_Registrar);
const syncKyc = () => mande(API_Sync_Kyc);

export async function userLoginRegistrar(req: LoginRequest) {

    
  return await loginApiRegistrar()
    .post<BaseResponse<string>>(req)
    .catch((error) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<string>;
    });
}
export async function userSyncKyc(req: SyncKycRequest) {
  return await syncKyc()
    .post<BaseResponse<string>>(req)
    .catch((error) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<string>;
    });
}

export async function apiUserInfo(req: UserInfoRequest) {
  return await userinfo_api()
    .post<BaseResponse<User>>(req)
    .catch((error) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<User>;
    });
}

export async function saveKyc(req: SaveKycRequest) {
  return await savekyc_api()
    .post<BaseResponse<string>>(req)
    .catch((error) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<string>;
    });
}

export async function apiUploadFile(
  event: Event,
  step: string,
  userType: string
): Promise<Response> {
  let formData = new FormData();
  // @ts-ignore
  let files = event.target.files[0];
  formData.append('file', files);
  formData.append('step', step);
  formData.append('type', userType);
  return await fetch(API_Upload, {
    method: 'POST',
    headers: {
      // 'Content-Type': ContentTypeEnum.FORM_DATA,
      Authorization: defaults.headers['Authorization']
    },
    body: formData
  });
}

export async function apiSubmit(req: SubmitKycRequest) {
  return await submit_api()
    .post<BaseResponse<string>>(req)
    .catch((error) => {
      return {
        code: 500,
        message: 'server error',
        data: null
      } as BaseResponse<string>;
    });
}
