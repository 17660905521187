
import type { NewNotification } from '@/model/model';
import { defineStore } from 'pinia';
import { getUserUnreadNewNotifications } from '@/api/newNotification';

export const useNewNotificationStore = defineStore({
    id: 'newNotifications',
    state: () => ({
        newNotifications: [] as NewNotification[]
    }),
    getters:{
        hasNewNotify(state){
            return state.newNotifications.length > 0
        },
    },
    actions: {
        async load(){
            console.log('refresh notification')
            const resp = await getUserUnreadNewNotifications();
            if(resp.code == 200){
                this.newNotifications = resp.data!
                console.log('get notification', this.newNotifications.length)
            }
        },
        isOfferRecievedRecord(id: number){
            return this.newNotifications.filter(n => n.notify_type == 'Offer Received' && n.related_biz_id == id).length > 0
        },
        isOffermade(id: number){
            return this.newNotifications.filter(n => (n.notify_type == 'Offer Accepted' || n.notify_type == 'Offer Rejected' || n.notify_type == 'Offer Expired') && n.related_biz_id == id).length > 0
        },
        findOffermadeRejectOrExpiredNotifyId(id: number){
            console.log(id, this.newNotifications)
            const items1 = this.newNotifications.filter(n => n.related_biz_id == id)
            console.log('check items', items1)
            const items = this.newNotifications.filter(n => (n.notify_type == 'Offer Rejected' || n.notify_type == 'Offer Expired') && n.related_biz_id == id)
            console.log('items', items)
            return items.length > 0 ? items[0].id : 0 
        },
        isNftOrder(id:number){
            return this.newNotifications.filter(n => (n.notify_type == 'Offer Completed' || n.notify_type == 'Purchase Completed') && n.related_biz_id == id).length > 0
        },
        findNftOrderNotifyId(id:number){
            const items = this.newNotifications.filter(n => (n.notify_type == 'Offer Completed' || n.notify_type == 'Purchase Completed') && n.related_biz_id == id)
            return items.length > 0 ? items[0].id : 0
        },
        isMintRequest(id:number){
            return this.newNotifications.filter(n => (n.notify_type == 'Mint Request Approved' || n.notify_type == 'Mint Request Rejected' || n.notify_type == 'Mint Request Completed') && n.related_biz_id == id).length > 0
        },
        findMintRequestNofityId(id:number){
            const items = this.newNotifications.filter(n => (n.notify_type == 'Mint Request Approved' || n.notify_type == 'Mint Request Rejected' || n.notify_type == 'Mint Request Completed') && n.related_biz_id == id)
            return items.length > 0 ? items[0].id : 0
        },
        isPaymentTransaction(id:number){
            return this.newNotifications.filter(n => n.notify_type == 'Sale Completed' && n.related_biz_id == id).length > 0
        },
        findTransactionNotifyId(id:number){
            const items = this.newNotifications.filter(n => n.notify_type == 'Sale Completed' && n.related_biz_id == id)
            return items.length > 0 ? items[0].id : 0
        },
        isRetire(id:number){
            return this.newNotifications.filter(n => n.notify_type == 'Retire Completed' && n.related_biz_id == id).length > 0
        },
        findRetireNotifyId(id:number){
            const items = this.newNotifications.filter(n => n.notify_type == 'Retire Completed' && n.related_biz_id == id)
            return items.length > 0 ? items[0].id : 0
        }
    }
})