<script setup lang="ts">
import { computed } from '@vue/reactivity';
const props = defineProps({
    category: String,
    status: String 
})

const imgSrc = computed(() => {
    if(props.category == '1'){
        return '/images/renewable_energy.png'
    } else if(props.category == '2') {
        return '/images/agriculture.png'
    } else if(props.category == '3') {
        return '/images/waste_management.png'
    } else if(props.category == '4') {
        return '/images/community_based_projects.png'
    } else if(props.category == '5') {
        return '/images/fuel_switching.png'
    }
    return '/images/renewable_energy.png'
})
</script>

<template>
    <img class="object-fill rounded" :src="imgSrc"/>
</template>