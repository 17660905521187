<script lang="ts" setup>
import { getTopRecs } from '@/api/rec';
import type { RecItem } from '@/model/model';
import { onMounted, ref } from '@vue/runtime-core';
import RecTopItem from '../rec/RecTopItem.vue';

const recItems = ref<RecItem[]>([])

onMounted(async () => {
  const resp = await getTopRecs()
  console.log(resp)
  if(resp.code == 200){
    recItems.value = resp.data!
  }
})
</script>

<template>
  <div class="px-4 mx-auto mt-12 xl:px-0 xl:max-w-7xl xl:container">
    <div class="flex">
      <div class="flex-1 text-2xl sm:text-3xl font-bold">REC</div>
      <a class="self-end text-base text-right text-mainGreen" href="/rec/list">
        See more >
      </a>
    </div>
    <div class="grid-cols-1 mt-4 divide-y gird">
      <div></div>
      <div></div>
    </div>
    <div class="pt-0 lg:grid lg:grid-cols-2 lg:gap-x-12">
      <RecTopItem v-for="item in recItems" :rec-item="item"></RecTopItem>
    </div>
  </div>
</template>
