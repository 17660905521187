import type { Banner, DataDictionary } from '@/model/model';
import type { WebSettingsResponse,  BaseResponse } from '@/model/reqRes';
import { API_DATA_DICTIONARY, API_GET_BANNER, API_GET_WEB_SETTINGS, API_IDS_COUNT } from '@/utils/const';
import { mande } from 'mande';

const dataDictionaryApi = () => mande(API_DATA_DICTIONARY)
const idsCountApi = () => mande(API_IDS_COUNT)
const getBannerApi = () => mande(API_GET_BANNER)

const getWebSettingsApi = () => mande(API_GET_WEB_SETTINGS)

export async function getDataDictionary(category: string) {
    return await dataDictionaryApi().get<BaseResponse<DataDictionary[]>>('/' + category).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<DataDictionary[]>; 
    });
}

export async function getIds(count: number) {
    return await idsCountApi().get<BaseResponse<number[]>>(count).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<number[]>; 
    });
}

export async function getBanners(){
    return await getBannerApi().get<BaseResponse<Banner[]>>("").catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<Banner[]>; 
    });
}

export async function getWebSettings() {
    return await getWebSettingsApi().get<BaseResponse<WebSettingsResponse>>("").catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<WebSettingsResponse>; 
    });
}